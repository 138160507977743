import React, {useState, useEffect}from 'react';
import './hero.css';
import { db } from '../../libs/firebase';
import { doc, getDoc} from "firebase/firestore";
import Slider from '../Slider';



function Hero() {
    const [card, setCard]=useState([]);

    const docRefdown = doc(db, 'infos', 'hIkTUoBj9FH5BE12DiaE');
    const pegandoDoc = async()=>{
        try {
          const docSnap = await getDoc(docRefdown);
          if(docSnap.exists()) {
            setCard(docSnap.data());
          } else {
              alert("Document does not exist")
          }
        }
        catch(error) {
         alert(error)
        }
        return(pegandoDoc());
      
      }
  
      useEffect(()=>{
  
        pegandoDoc();
        increase()
      });

      const [nr, setNr]=useState(0)
      const [nr2, setNr2]=useState(0)
      const [nr3, setNr3]=useState(0)
      const increase = ()=>{
       
  
          setTimeout(()=> {
            setNr(nr+1)
  
          }, 50);
          setTimeout(()=> {
            setNr2(nr2+100)
  
          }, 5);

          setTimeout(()=> {
            setNr3(nr3+1)
  
          }, 5);

        
      };
  
      


  return (
    <section className='hero'>
        <div className="heroContainer">
            <div className="heroContent1">
                <div className="heroText">Local knowledge in a globalized world</div>
                <Slider />
            </div>
            <div className='heroContent2'>
                <div className="numbersItem">
                  {card.anos?
                    <div className="numberNumber">{card.anos}</div>
                    :
                    <div className="numberNumber">{nr} years</div>
                  }
                    <div className="numberText">generating value</div>
                </div>
                <div className="numbersItem">
                {card.projetos?
                <div className="numberNumber">{card.projetos}</div>
                :
                <div className="numberNumber">{nr2}</div>
                  }
                    <div className="numberText">projects completed</div>
                </div>
                <div className="numbersItem">
                  {card.locais? 
                <div className="numberNumber">{card.locais}</div>
                :
                <div className="numberNumber">{nr3}</div>
              }
                    <div className="numberText">jurisdictions</div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Hero