import React from 'react';
import './services.css'

function Services() {
  return (
    <section className="services" id='services'>
        <div className="servicesContainer">
            <div className="sevicesTitle">
                Our Services
            </div>
            <div className="servicesContent">
                <div className="servicesDesc">
                Our activities combine public domain research with discrete interviews. We leverage our own methodology and our extensive network of contacts and partners, built up over two decades of activity, to handle scopes that are designed on a case-by-case basis, according to the client’s needs.
                </div>
                <div className="servicesList">
                    <ul>
                        <li>Pre-M&A due diligence</li>
                        <li>Executive screening report</li>
                        <li>Public record research</li>
                        <li>Political environment survey</li>
                        <li>Market analysis</li>
                        <li>Stock forecast</li>
                        <li>Rapid response monitoring</li>
                        <li>Asset tracing</li>
                    </ul>
                </div>
            </div>
        </div>

    </section>
  )
}

export default Services