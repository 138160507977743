import React from 'react'
import './newscard.css'

function NewsCard({title, description, link,  thumb }) {
  return (
    <div className="cardItem">
      <div className="cardimage"><img src={thumb} alt={title} width={'100%'}/></div>
      <div className="cardinfo">
        <div className="cardTitle">{title}</div>
        <div className="carddescr">{description}</div>
        <div className="cardurl"><a href={link} target="_blank" rel="noopener noreferrer">See More</a></div>
      </div>
      
    </div>
  )
}

export default NewsCard