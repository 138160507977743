import React, {useState} from 'react';
import Logo from '../../image/logo_branco.webp';
import './header.css'
import Menu from '../../image/menuMB.webp'
import MenuX from '../../image/menuCloe.webp'

function Header() {
  const [see, setSee]=useState(false);

  const handleToglle = ()=>{
    setSee(!see)

  }

  return (
    <header>
        <div className="headerContainer">
            <div className="headerLogo"><img src={Logo} alt="Logo Nexointell" width={280}/></div>
            <nav className="headerNav">
                <ul>
                    <li><a className='navDesk' href="#services">Services</a></li>
                    <li><a className='navDesk' href="#industries">Industries</a></li>
                    <li><a className='navDesk' href="#values">Values</a></li>
                    <li><a className='navDesk' href="#presence">Presence</a></li>
                    <li><a className='navDesk' href="#team">Our Experts</a></li>
                    <li><a className='navDesk' href="#news">News</a></li>
                    <li><a className='navDesk' href="#contact">Contact Us</a></li>
                </ul>
            </nav>
            <div className="menuHamburguer">
              {!see &&
              <button className='menuIcon' onClick={handleToglle}><img src={Menu} alt="menu" width={40} /></button>
              }
              {see &&
              <button className='menuIcon' onClick={handleToglle}><img src={MenuX} alt="menu" width={40} /></button>
              }
            </div>
        </div>
        <div className="menuMobile" style={{
         top: see ? '183px' : '-500px',
         backgroundColor: see ? '#2F2E2E' : 'transparent'
        }}>
          <ul>
            <li><a href="#services" onClick={handleToglle}>Services</a></li>
            <li><a href="#industries" onClick={handleToglle}>Industries</a></li>
            <li><a href="#values" onClick={handleToglle}>Values</a></li>
            <li><a href="#presence" onClick={handleToglle}>Presence</a></li>
            <li><a href="#team" onClick={handleToglle}>Our Experts</a></li>
            <li><a href="#news" onClick={handleToglle}>News</a></li>
            <li><a href="#contact" onClick={handleToglle}>Contact Us</a></li>
          </ul>
        </div>
    </header>
  )
}

export default Header