import React, {useState, useEffect} from 'react'
import { useParams, useNavigate} from 'react-router-dom';
import { db } from '../../libs/firebase';
import { doc, getDoc, updateDoc} from "firebase/firestore"
import SIstemSidebar from '../../components/SistemSideBar';

import './newsupdade.css'


function NewsUpdate() {
  const navigate = useNavigate()
  const [uploading, setUploading] = useState(false);
  const [title, setTitle]=useState('');
  const [description, setDescription]=useState('');
  const [link, setLink]=useState('');
  const [thumb, setThumb]=useState('');
  const [updated, setUpdated]=useState(false);
  const [erros, setErros]=useState(false);
  const [erro, setErro]=useState('');
  const [open, setOpen]=useState(false)

    
    const { id } = useParams();
    const [card, setCard]=useState([]);
    const docRefdown = doc(db, 'materias', id);

    const pegandoDoc = async()=>{
      try {
        const docSnap = await getDoc(docRefdown);
        if(docSnap.exists()) {
          setCard(docSnap.data());
        } else {
            alert("Document does not exist")
        }
      }
      catch(error) {
       alert(error)
      }
      return(pegandoDoc());
    
    }

    useEffect(()=>{

      pegandoDoc();
    });

    const handleUpdate = (e)=>{
      e.preventDefault();
      setUploading(true);
      const docRef = doc(db, 'materias', id);
      const data ={
        title: title,
        description: description,
        link: link,
        thumb:thumb,
      };
      setUploading(false);
      updateDoc(docRef, data, { merge:true })
      .then(docRef => { setUpdated(true) })
      .catch(error => { setErros(true); setErro(error); })
      setOpen(false)
  
    }

    const handleOpen=()=>{
      setOpen(true)
      setUpdated(false)
      setTitle(card.title);
      setThumb(card.thumb);
      setLink(card.link);
      setDescription(card.description);

    }
    const handleClose=()=>{
      setOpen(false)

    }

    const handleBack = ()=> {
     
      navigate('/newslist')
    };

  

  return (
    <>
    <div className="sisContainer">

        <SIstemSidebar />

        <div className="contentArea">
 
        <div className="contentTitle">News Update</div>
        <div className="formArea2">

            <img src={card.thumb} alt="Nothing show on this Item"  width={200} height={'auto'} className='form-foto'/>
      
            
            <p className="label3">Title:<br/> <span>{card.title}</span></p>
            
            <p className="label3">Description:<br/> <span>{card.description}</span></p>
            
            <p className="label3">News Link:<br/> <span>{card.link}</span></p>

            <p className="label3">Thumb URL:<br/> <span>{card.thumb}</span></p>
           
            
            
            
            <button className='fill' onClick={handleOpen}>UPDATE</button>
            <button className='fill' onClick={handleBack}>BACK</button>

            


      </div>
             
             

             
             
        </div>


    </div>
    {open &&
        <div className="popUp">
          <div className="formArea2" >
            
              <form className="newsForm" onSubmit={handleUpdate}>
              <img src={thumb} alt="Insert Thumb URL"  width={200} height={'auto'} className='form-foto'/>
                <p className="label">Title</p>
                <input type="text" value={title} required onChange={(e)=>{setTitle(e.target.value)}} style={{backgroundColor:'#fff'}}/>
                <p className="label">Description</p>
                <textarea value={description} required onChange={(e)=>{setDescription(e.target.value)}}style={{backgroundColor:'#fff'}}/>
                <p className="label">News Link</p>
                <input type="text" value={link} required  onChange={(e)=>{setLink(e.target.value)}} style={{backgroundColor:'#fff'}}/>
                <p className="label">Thumb URL</p>
                <input type="text"  value={thumb} required onChange={(e)=>{setThumb(e.target.value)}} style={{backgroundColor:'#fff'}}/>
                
                <input type="submit" value="SEND"/>
                <button className='fill' onClick={handleClose}>Close</button>

                  {uploading &&
                    <span>uploading...</span>
                  }
                  {updated &&
                    <span>Entire Document has been updated successfully</span>
                  }
                  {erros &&
                    <span className='Erro'> Erro:{erro}<br/> Try agin later</span>
                  }
                  
                </form>
        </div>

        </div>
    }
    </>
  )
}

export default NewsUpdate