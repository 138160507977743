import React, {useState, useRef} from 'react'
import Carousel , { consts} from 'react-elastic-carousel'
import './slider.css'
import Vai from '../../image/vai.webp';
import Volta from '../../image/volta.webp';
import {sliderData} from './sliderData';

function Slider() {
  const items = [...sliderData]
  const carouselRef = useRef(null);
  const totalPages = items.length;
  let resetTimeout;

  const [corrent, setCorrente]=useState(0);
 

  const myArrow = ({ type, onClick, isEdge })=>{
    const pointer = type === consts.PREV ? <img src={Volta} width={'100%'} height={'auto'} alt="volta" /> : <img src={Vai} width={'100%'} height={'auto'} alt="vai" />
    return (
      <button onClick={onClick} disabled={isEdge} className='sButton'>
        {pointer}
      </button>
    )
  }

  return (
    <div className="slider">
            <Carousel
            ref={carouselRef}
            verticalMode
            enableAutoPlay  autoPlaySpeed={5000}
            pagination={false}

            renderArrow={myArrow}
            onNextEnd={({ index }) => {
              clearTimeout(resetTimeout)
              if (index + 1 === totalPages) {
                resetTimeout = setTimeout(() => {
                    carouselRef.current.goTo(0)
                }, 5000) // same time
              }
            }}
            onChange={(currentItem, pageIndex) =>

              setCorrente(pageIndex)
        
            }
            >

              {items.map(item => 
                
                <div className="slideWarper" key={item.id}>
                        <div className="slideItem">
                            <div className={`slideWho ${corrent === item.id ? 'slideWhoactive' : 'slideWhoInactive' }`} >
                                <span className='slideName'>{item.name}</span><br/>
                                <span className='slideRole'>{item.role}</span><br/>
                                <span className='slideLocl'>{item.locale}</span>
                            </div>
                            <div className="slideWhat">
                                {item.text}
                            </div>
                        </div>
              </div>
                
              )}
            
        
            </Carousel>
    </div>
  )
}

export default Slider