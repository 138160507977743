import React, {useState, useEffect} from 'react';
import { doc, updateDoc, getDoc } from "firebase/firestore";
import SIstemSidebar from '../../components/SistemSideBar';
import { db } from '../../libs/firebase';
import './dataupdate.css'


function DataUpdate() {
  const [uploading, setUploading] = useState(false);
  const [anos, setAnos]=useState('');
  const [locais, setLocais]=useState('');
  const [projetos, setProjetos]=useState('');
  const [updated, setUpdated]=useState(false);
  const [erros, setErros]=useState(false);
  const [erro, setErro]=useState('');
  const [open, setOpen]=useState(false)
  
  const [card, setCard]=useState([]);
  const docRefdown = doc(db, "infos", "hIkTUoBj9FH5BE12DiaE");

  const pegandoDoc = async()=>{
    try {
      const docSnap = await getDoc(docRefdown);
      if(docSnap.exists()) {
        setCard(docSnap.data());
      } else {
          alert("Document does not exist")
      }
    }
    catch(error) {
     alert(error)
    }
    return(pegandoDoc());
  
  }

  useEffect(()=>{

    pegandoDoc();
  });

    const handleUpdate = async (e)=>{
      e.preventDefault();
      setUploading(true);
      const docRef = doc(db, "infos", "hIkTUoBj9FH5BE12DiaE");
      const data ={
        anos: anos,
        locais: locais,
        projetos: projetos,
      };
      setUploading(false);
      await updateDoc(docRef, data, { merge:true })
      .then(docRef => { setUpdated(true) })
      .catch(error => { setErros(true); setErro(error); })

      setAnos('');
      setLocais('');
      setProjetos('');
      setUpdated(false);
      setOpen(false);
    }



    const handleClose=()=>{
      setOpen(false)

    }

    const handleOpen=()=>{
      setOpen(true);
      setUpdated(false);
      setAnos(card.anos);
      setProjetos(card.projetos);
      setLocais(card.locais);

    }


  return (
    <>
    <div className="sisContainer">
        <SIstemSidebar />

        <div className="contentArea">
       
        <div className="contentTitle">Data Update</div>

          <div className="formArea2" >
          
            
                <p className="label2">Years: <span>{card.anos}</span></p>
                
                <p className="label2">Projects: <span>{card.projetos}</span></p>
                
                <p className="label2">Jurisdictions:  <span>{card.locais}</span></p>
                <button className='fill' onClick={handleOpen}>UPDATE</button>
          
        </div>
      </div>
      
    </div>
    {open &&
        <div className="popUp">
          <div className="formArea2" >
            
              <form className="newsForm" onSubmit={handleUpdate}>
                  <p className="label">Years</p>
                  <input type="text"  value={anos} required onChange={(e)=>{setAnos(e.target.value)}} style={{backgroundColor:'#fff'}}/>
                  <p className="label">Projects</p>
                  <input type="text" value={projetos} required onChange={(e)=>{setProjetos(e.target.value)}} style={{backgroundColor:'#fff'}}/>
                  <p className="label">Jurisdictions</p>
                  <input type="text" value={locais} required onChange={(e)=>{setLocais(e.target.value)}} style={{backgroundColor:'#fff'}}/>
                
                  
                  <input type="submit" value="SEND"/>
                  <button className='fill' onClick={handleClose}>Close</button>

                  {uploading &&
                    <span>uploading...</span>
                  }
                  {updated &&
                    <span>Entire Document has been updated successfully</span>
                  }
                  {erros &&
                    <span className='Erro'> Erro:{erro}<br/> Try agin later</span>
                  }
                  
                </form>
        </div>

        </div>
    }
        
   
    </>
    
  )
}

export default DataUpdate