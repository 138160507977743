import React from 'react'
import './values.css'

function Values() {
  return (
    <section className="values" id='values'>
      <div className="valueContainer">
        <div className="valueContent">


          <div className="valueTitle">
            Values
          </div>

          <div className="valueItem">
            <div className="valueItemTitle">
            Legality
            </div>
            <div className="valueItemText">
            Full compliance with local information access rules.
            </div>
          </div>
          <div className="valueItem">
            <div className="valueItemTitle">
            Confidentiality
            </div>
            <div className="valueItemText">
            Strict non-disclosure agreements and data compartmentalization.
            </div>
          </div>
          <div className="valueItem">
            <div className="valueItemTitle">
            Corporate Standards
            </div>
            <div className="valueItemText">
            Market intelligence in line with industry best practices.
            </div>
          </div>
          <div className="valueItem">
            <div className="valueItemTitle">
            Accuracy
            </div>
            <div className="valueItemText">
            Verification by cross-referencing with multiple sources.
            </div>
          </div>
          <div className="valueItem">
            <div className="valueItemTitle">
            Ethics
            </div>
            <div className="valueItemText">
            Access to information through ethical procedures.
            </div>
          </div>

        </div>

      </div>
    </section>
  )
}

export default Values