import React, {useState, useEffect} from 'react'
import './imageupload.css';
import SIstemSidebar from '../../components/SistemSideBar';
import ImageItem from '../../components/ImageItem';
import * as Images from '../../services/photos';




function ImageUpload() {

  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(()=>{
    getImages() ;
  },[]);
  
  const getImages = async()=>{
    setLoading(true);
  
    setImages(await Images.getAllNews());
  
    setLoading(false);
  }
  
  const handleNewsImage = async (e) => {
    e.preventDefault();
  
    const formData = new FormData(e.currentTarget);
    const file = formData.get('news');
  
    if(file && file.size > 0) {
      setUploading(true);
      let result = await Images.insertNews(file);
      setUploading(false);
  
      if(result instanceof Error) {
        alert(`${result.name} - ${result.message}`);
      } else {
        let newPhotoList = [...images];
        newPhotoList.push(result);
        setImages(newPhotoList);
      }
    }
  }
  
  const handleDeleteClick = async (name) => {
    await Images.deleteNewsPhoto(name);
    getImages();
  }

  return (
    <div className="sisContainer">
      <SIstemSidebar />
      <div className="contentArea">
       
        <div className="contentTitle">Upload Images</div>
        {loading &&
          <div className="loading">
            Loading....
          </div>
        
        }
        <div className="imageArea">
            {images.map((item, index)=>(
              <ImageItem key={index} url={item.url} name={item.name}  onDelete={handleDeleteClick} />
            ))}
        </div>


        <div className="formArea">
          <form  method='POST' onSubmit={handleNewsImage}  className="youtubeform">
              <p className='youName'>Send New Image</p>
              <input type="file" name="news" />
              <input type="submit" value="SEND" />
              {uploading &&
                <span className='uploading'>uploading...</span>
              }
            </form>
        </div>

      </div>
      

    </div>

  )
}

export default ImageUpload