import React, {useState, useEffect} from 'react';
import SIstemSidebar from '../../components/SistemSideBar';
import { doc, onSnapshot, orderBy, query, deleteDoc} from "firebase/firestore"
import {newsCollectionRef} from '../../services/fireref';
import NewsItem from '../../components/NewsItem';
import './newslist.css';


function NewsList() {
  const [cards, setCards]=useState([]);
  const [loading, setLoading]=useState(false);


  useEffect(()=>{
    
      const q = query(newsCollectionRef, orderBy("time", "desc"));
      setLoading(true);
      const change = onSnapshot(q, snapshot=>{
        setCards(snapshot.docs.map(doc=>({
          data:doc.data(),
          id:doc.id
        })))
        setLoading(false);
      })
      
      return()=>{change()}
  },[]);

  const deleteReport = async (id) => {
    const colRef = newsCollectionRef;
    await deleteDoc(doc(colRef, id));
  };

  
  return (

    <div className="sisContainer">

      <SIstemSidebar />

      <div className="contentArea">
       
        <div className="contentTitle">News List</div>
        {loading &&
          <div className="loading">
            Loading....
          </div>
        
        }
   
        <div className="newsarea">

              {cards.map((item, index)=>(
                <NewsItem key={index} title={item.data.title}  description={item.data.description} link={item.data.link} thumb={item.data.thumb}  onDelete={deleteReport} id={item.id} />
              ))}

        </div>
      </div>

    </div>
    
  )
 

}

export default NewsList