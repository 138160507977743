import React, {useEffect, useState} from 'react'
import './news.css';
import NewsCard from '../NewsCard';
import { newsCollectionRef } from '../../services/fireref';
import {onSnapshot, orderBy, query } from "firebase/firestore"
import Carousel , { consts} from 'react-elastic-carousel';
import Vai from '../../image/nVai.webp';
import Volta from '../../image/nVolta.webp';

function News() {

  const [cards, setCards]=useState([]);

  const myArrow = ({ type, onClick, isEdge })=>{
    const pointer = type === consts.PREV ? <img src={Volta} width={'100%'} height={'auto'} alt="volta" /> : <img src={Vai} width={'100%'} height={'auto'} alt="vai" />
    return (
      <button onClick={onClick} disabled={isEdge} className='nButton'>
        {pointer}
      </button>
    )
  }

  const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1, itemPadding:[0, 5] },
    { width: 320, itemsToShow: 2, itemsToScroll: 2, itemPadding:[0, 10]  },
    { width: 720, itemsToShow: 3, itemsToScroll: 3, itemPadding:[0, 10] },

  ]

  

  useEffect(()=>{
    
    const q = query(newsCollectionRef, orderBy("time", "desc"));
    
    const change = onSnapshot(q, snapshot=>{
      setCards(snapshot.docs.map(doc=>({
        data:doc.data(),
        id:doc.id
      })))
     
    })
    
    return()=>{change()}
  },[]);






  return (
    <section className="news" id='news'>
      <div className="newsContainer">
          <div className="newsTitle">
            News
          </div>
          <div className="newsThumbs" >
            <Carousel
              breakPoints={breakPoints}
              renderArrow={myArrow}

            >
              {cards.map((item, index)=>(
                  <NewsCard key={index} title={item.data.title}  description={item.data.description} link={item.data.link} thumb={item.data.thumb}/>
              ))} 
            </Carousel>
          </div>
          

      </div>
    </section>
  )
}

export default News