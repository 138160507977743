import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {UserAuth} from '../../services/AuthContext';
import Logo from '../../image/logo_branco.webp';
import './login.css'

function Login() {
  const navigate = useNavigate(); 
  const {signIn} = UserAuth();
  const [email, setEmail]=useState('');
  const [password, setPassword]=useState('');
  const [error, setErrors]=useState('');

  const handleLogin =async(event)=>{

      event.preventDefault();
      setErrors('');
      try{
          await signIn(email,password);
          navigate("/dataupdate");
      }catch(e){
          setErrors(e.message);
          console.log(e.message);
      }
      
  }

  return (
     <div className="locontainer">
            <div className="loform">
                <div className="loimage">
                    <img src={Logo} alt="logo" width={320} />
                </div>

                <form onSubmit={handleLogin}>
                    <input type="email" placeholder="email" required onChange={(e)=>setEmail(e.target.value)} style={{backgroundColor:'#fff'}} />
                    <input type="password" placeholder="password" required onChange={(e)=>setPassword  (e.target.value)} style={{backgroundColor:'#fff'}}/>
                    <button>Login</button>
                </form>
                {error &&
                <div className='loError'>
                    Incorrect username or password<br/>
                    Try again<br/>
                </div>
                }
            </div>
        </div>
  )
}

export default Login