
import './App.css';
import {BrowserRouter} from 'react-router-dom';
import {RouteList} from './routes/Routes'

function App() {
  return (
    <BrowserRouter>
      <RouteList />
    </BrowserRouter>
  );
}

export default App;
