import React from 'react'
import './industres.css'
import Industry from './industries.json';

function Industries() {



  return (
    <section className="industries" id='industries'>
        <div className="industriesContainer">
            <div className="industriesContent">
                <div className="inndustriesTitle">
                    Industries
                </div>
                <div className="industriesThumbs">

                    {
                        [...Industry].map( cards=>{
                            return(
                                <div className="thumbItem" key={cards.id}>
                                    <div className="thumbImage">
                                        <img src={cards.thumb} alt={cards.title} width={cards.width} height={cards.height} className="thumbImg" />
                                    </div>
                                    <div className="thumbName">
                                        {cards.title}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </section>
  )
}

export default Industries