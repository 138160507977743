export const sliderData = [
    {
        id: 0,
        name:"Paul Doran",
        role:"VP Head of Investigations Telenor Group ASA,",
        locale:"Norway",
        text:"I am very happy to endorse Nexointell as a provider of investigation and integrity due dilligence services. During my time as Director of Investigations (Latin America) based in São Paulo and latterly Bogotá for a major UK-based risk consultancy, I was always highly satisfied and impressed with the work Nexointell completed on our behalf."
    },
    {
        id: 1,
        name:"Peter D. Woglom",
        role:"Managing Director",
        locale:"BDO, New York",
        text:"Nexointell have assisted on a wide range of matters across Latin America for me and are always upfront and transparent regarding their ability to deliver. The quality of research and sources are always high-level. It's always a pleasure to work with Nexointell and I enjoy watching the company grow and succeed.",
    },
    {
        id: 2,
        name:"Alexis Loyer",
        role:"Former deputy Head of the Business",
        locale:"Directorate at the Ministry of Foreign Affairs, France",
        text:"Everytime I entrust an investigation to Nexointell, I know that precise, reliable and updated information will be gathered by its team, whatever the target may be. They always deliver on time, they are stealthy and they can have access to high added value human sources in many Latin american countries.",
    },
    {   id: 3,
        name:"Niels Lindholm",
        role:"Ethical Compliance Officer",
        locale:"International Association of Athletics Federations, Mónaco",
        text:"In the business intelligence sector it is difficult to find partners who will consistently deliver on their promises, if not more. Over a period of 10 years Nexointell and his team have repeatedly shown that they can, and will, do exactly that. They are well placed to discuss complex BI assignments in Latin America and the Iberian region.",
    },
    {   id: 4,
        name:"Gavin Sinclair",
        role:"Managing Director",
        locale:"Palladian, South Africa",
        text:" Palladian has been using NexoIntell for several years as its sole provider of due diligence and intelligence in Latin America. Their attention to detail, professionalism, reliability, and subject expertise is unequalled in the region. We never had any reason to go somewhere else.",
    },
    {   id: 5,
        name:"Davide Sattin",
        role:"Managing Director",
        locale:"Scrander Sàrl, Switzerland",
        text:" I highly recommend Nexointell.  I happened to be in a tight spot in terms of budget and timeframe, and yet they were kind, accommodating and delivered high quality granular intelligence, reflecting the breadth of their source network and their ability to ask the right questions.  If you need investigative assistance in Latin America, Nexointell is most definitely your company.",
    },
]



 

    