import React from 'react'
import './floatwhats.css';
import WhatsM from '../../image/iconeWM.webp';

function FloatWhats() {
  return (
    <>
        <div className="floatwDesk">
          <a href="https://wa.me/351968437876" target='_blank' rel="noopener noreferrer" className="floatwLink">
              <div className="iconW">
              <img src={WhatsM} alt="Whatsapp" width={'100%'} height={'auto'}/>
              </div>
              <div className="TextW">
              Talk with<br/> the Team!
              </div>
          </a>
        </div>
        <div className="floatwMob">
            <a href="https://wa.me/351968437876" target='_blank' rel="noopener noreferrer" className="floatwLink"><img src={WhatsM} alt="Whatsapp" width={'100%'} height={'auto'}/></a>
            
        </div>
    </>
  )
}

export default FloatWhats