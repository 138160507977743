import React from 'react';
import './newsitem.css';
import { Link } from 'react-router-dom';



function NewsItem({title, description, link,  thumb, onDelete, id }) {


  return (
    <div className="listItem">
      <div className="listimage"><img src={thumb} alt={title} width={'100%'}/></div>
      <div className="listinfo">
        <div className="listTitle">{title}</div>
        <div className="listdescr"><div>{description}</div></div>
        <div className="divlisturl">{link}</div>
      </div>
      <div className="listDelete">
        <Link to={"/newsupdate/"+id}>See Item</Link>

        <button className='delete' onClick={()=>onDelete(id)}>Delete</button>
      </div>
    </div>
  )
}

export default NewsItem