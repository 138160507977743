import React, {useState} from 'react'
import './sistemsidebar.css';
import { Link, useNavigate} from 'react-router-dom';
import { UserAuth } from '../../services/AuthContext';
import Logo from '../../image/logo_branco.webp';
import Menu from '../../image/menuMB.webp'
import MenuX from '../../image/menuCloe.webp'


function SIstemSidebar() {

  const [see, setSee]=useState(false);

  const handleToglle = ()=>{
    setSee(!see)

  }
  const navigate = useNavigate();
  const{logout} = UserAuth();
  const handleLogout = async()=>{
      try{
          await logout();
          navigate('/')
      }catch(e){
          console.log(e.message)
      }
  }

  return (
    <>
    <div className="sideBarContainer">
        <div className="sedeBarLogo"><img src={Logo} alt="Logo" width={250} /></div>
        <div className="sideBarDiv">Data Update</div>
        <Link to='/dataupdate' className="sideBarLink">Update Data</Link>
        
        <div className="sideBarDiv">News Update</div>
        <Link to='/imageupload' className="sideBarLink">Upload Image</Link>
        <Link to='/imagelist' className="sideBarLink">Image List</Link>
        <Link to='/newsupload' className="sideBarLink">Upload News</Link>
        <Link to='/newslist' className="sideBarLink">News List</Link>


        <div className="sideBarLogOut"><button onClick={handleLogout}>Log Out</button></div>

    </div>

      <div className='sideBarMob'>
        <div className="sedeBarLogo"><img src={Logo} alt="Logo" width={180} /></div>
        
        <div className="menuSB">
              {!see &&
              <button className='menuIcon' onClick={handleToglle}><img src={Menu} alt="menu" width={40} /></button>
              }
              {see &&
              <button className='menuIcon' onClick={handleToglle}><img src={MenuX} alt="menu" width={40} /></button>
              }
        </div>
      </div>
        <div className="SBMobile" style={{
         top: see ? '116px' : '-500px',
         backgroundColor: see ? '#002333' : 'transparent'
        }}>
          <div className="sideBarDiv">Data Update</div>
            <Link to='/dataupdate' className="sideBarLink" onClick={handleToglle} >Update Data</Link>
          
            <div className="sideBarDiv">News Update</div>
            <Link to='/imageupload' className="sideBarLink" onClick={handleToglle}>Upload Image</Link>
            <Link to='/imagelist' className="sideBarLink" onClick={handleToglle}>Image List</Link>
            <Link to='/newsupload' className="sideBarLink" onClick={handleToglle}>Upload News</Link>
            <Link to='/newslist' className="sideBarLink" onClick={handleToglle}>News List</Link>


            <div className="sideBarLogOut"><button onClick={handleLogout}>Log Out</button></div>
          </div>
      
    </>
  )
}

export default SIstemSidebar