import React from 'react'
import './team.css'
import Ft1 from '../../image/ft1.webp'
import Ft2 from '../../image/ft2.webp'
import Linkedin from '../../image/linkedin.webp'


function Team() {
  return (

    <section className="team" id='team'>
        <div className="teamContainer">
            <div className="teamContent">
                <div className="teamTitle">
                Team
                </div>
                <div className="teamItem">
                    <div className="temImage"><img src={Ft1} alt="LUIS ESNAL" width={'auto'} height={"auto"}/></div>
                    <div className="teamInfo">
                        <div className="teamNameContent">
                            <div className="teamName">LUIS ESNAL</div>
                            <div className="teamLink"><a href="https://www.linkedin.com/in/luis-esnal-b626795/" target='_blank'rel="noopener noreferrer"><img src={Linkedin} alt="Linkedin"  width={'auto'} height={"auto"} /></a></div>
                        </div>
                        <div className="teamRole">CEO/Founding Partner</div>
                        <div className="temHistory">
                        Born in Argentina, Luis spent over 25 years in São Paulo, Brazil, where he founded Nexointell, in 2002, following successful experiences as an international correspondent and investigative journalist.<br/><br/>
                        Throughout his career Luis became specialized in corporate intelligence and has worked on-the-ground in countries such as China, U.S., Colombia, Argentina, Mexico, Chile and Uruguay.
                        </div>
                    </div>
                </div>
                <div className="teamItem">
                    <div className="temImage"><img src={Ft2} alt="ANTONIO ELIAS"   width={'auto'} height={"auto"}/></div>
                    <div className="teamInfo">
                        <div className="teamNameContent">
                            <div className="teamName">ANTONIO ELIAS</div>
                            <div className="teamLink"><a href="https://www.linkedin.com/in/antonio-e-0246a81a/" target='_blank' rel="noopener noreferrer" ><img src={Linkedin} alt="Linkedin"  width={'auto'} height={"auto"}/></a></div>
                        </div>
                        <div className="teamRole">Executive Director/Partner</div>
                        <div className="temHistory">
                        Antonio joined Nexointell in 2022, after over 12 years of intelligence experience in international consultancies and as an independent consultant. Throughout his career Antonio was directly involved in hundreds of intelligence cases in Latin America.<br/><br/>
                        Antonio holds degrees in Political Science (American University, Washington, D.C.) and Law (FMU, São Paulo).
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Team