import React from 'react'
import Header from '../components/Header'
import Hero from '../components/Hero'
import Services from '../components/Services'
import Industries from '../components/Industries'
import Values from '../components/Values'
import Presence from '../components/Presence'
import Team from '../components/Team'
import Footer from '../components/Footer'
import News from '../components/News'
import FloatWhats from '../components/FloatWhats'
import FloatTop from '../components/FloatTop'

function Home() {
  return (
    <>
        <Header />
        <Hero />
        <Services />
        <Industries />
        <Values />
        <Presence />
        <Team />
        <News />
        <Footer />
        <FloatWhats />
        <FloatTop />
    </>
  )
}

export default Home