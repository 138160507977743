import React, {useState} from 'react'
import './presence.css';
import Arg from '../../image/arg.webp';
import Bra from '../../image/bra.webp';
import Chi from '../../image/chi.webp';
import Col from '../../image/col.webp'; 
import Cos from '../../image/cos.webp';
import Ecu from '../../image/ecu.webp'; 
import Mex from '../../image/mex.webp'; 
import Par from '../../image/par.webp';
import Per from '../../image/per.webp';
import Spa from '../../image/esp.webp';
import Eua from '../../image/eua.webp';
import Ven from '../../image/ven.webp';

function Presence() {
  //ativando botoes
  const [pais, setPais]=useState("arg");


  return (

    <section className="presence" id='presence'>
      <div className="preContainer">
        <div className="preTitle">
        Presence
        </div>
        <div className="preButtonArea">
            <button className={`preButton ${pais === "arg" ? 'activeButton' : '' }`} onClick={()=>{setPais("arg")}}>Argentina</button>
            <button className={`preButton ${pais === "bra" ? 'activeButton' : ''  }`} onClick={()=>{setPais("bra")}}>Brazil</button>
            <button className={`preButton ${pais === "chi" ? 'activeButton' : ''  }`} onClick={()=>{setPais("chi")}} >Chile</button>
            <button className={`preButton ${pais === "col" ? 'activeButton' : ''  }`} onClick={()=>{setPais("col")}} >Colombia</button>
            <button className={`preButton ${pais === "cos" ? 'activeButton' : ''  }`} onClick={()=>{setPais("cos")}} >Costa Rica</button>
            <button className={`preButton ${pais === "ecu" ? 'activeButton' : ''  }`} onClick={()=>{setPais("ecu")}} >Ecuador</button>
            <button className={`preButton ${pais === "mex" ? 'activeButton' : ''  }`} onClick={()=>{setPais("mex")}} >Mexico</button>
            <button className={`preButton ${pais === "par" ? 'activeButton' : ''  }`} onClick={()=>{setPais("par")}} >Paraguay</button>
            <button className={`preButton ${pais === "per" ? 'activeButton' : ''  }`} onClick={()=>{setPais("per")}} >Peru</button>
            <button className={`preButton ${pais === "spa" ? 'activeButton' : ''  }`} onClick={()=>{setPais("spa")}} >Spain </button>
            <button className={`preButton ${pais === "eua" ? 'activeButton' : ''  }`} onClick={()=>{setPais("eua")}} >United States</button>
            <button className={`preButton ${pais === "ven" ? 'activeButton' : ''  }`} onClick={()=>{setPais("ven")}} >Venezuela </button>
        </div>
        <div className="preItemArea">
            <div className={`preItem ${pais === "arg" ? 'preItemActive' : '' }`}>
                <div className="preItemMap">
                      <img src={Arg} alt="Argentina" width={'100%'} height={"auto"} />
                </div>
                <div className="preItemInfo">

                  <div className="preItemTitle">Argentina | Case Study: Retail</div>
                  <div className="preItemText">
                  Nexointell conducted an in-depth investigation comprised of discrete interviews and public record research on behalf of an international law firm and its end client. The objective was to understand the asset position, financial strength and potential political and judicial connections maintained by an Argentina-based company that broke a distribution agreement with the client. With the results obtained, the client was able to present litigation arguing the violation of the distribution agreement and the bad-faith of its local distributor.
                  </div>
                </div>
            </div>


            <div className={`preItem ${pais === "bra" ? 'preItemActive' : '' }`}>
                <div className="preItemMap">
                      <img src={Bra} alt="Brazil" width={'100%'} height={"auto"} />
                </div>
                <div className="preItemInfo">

                  <div className="preItemTitle">Brazil | Case Study: Oil & Gas</div>
                  <div className="preItemText">
                  Between 2015 and 2020, we gathered mission-critical intelligence on over 60 companies involved directly or indirectly, in Operação Lavajato (Operation Carwash). The operation started in Brazil became one of the largest global anticorruption investigations.<br/>
                  Nexointell played a fundamental role in supporting multinational oil and gas companies, international investors and other players in obtaining on-the-ground intelligence. During this period, we developed a strong network of sources within the Brazilian judiciary, financial services players, and international oil companies to help clients to understand their exposure vis-à-vis assets and investments, as well as project tendencies and future outlook for the investigation and business continuity in Brazil.
                  </div>
                </div>
            </div>


            <div className={`preItem ${pais === "chi" ? 'preItemActive' : '' }`}>
                <div className="preItemMap">
                      <img src={Chi} alt="Chile" width={'100%'} height={"auto"} />
                </div>
                <div className="preItemInfo">

                  <div className="preItemTitle">Chile | Case Study: Energy</div>
                  <div className="preItemText">
                  On behalf of a renewable energy company based in Canada, Nexointell conducted public record due diligence reports of five companies active in Chile’s solar energy industry. All companies were targets of potential acquisition by the client and the project served as one of the supporting elements of their investment decision.
                  </div>
                </div>
            </div>


            <div className={`preItem ${pais === "col" ? 'preItemActive' : '' }`}>
                <div className="preItemMap">
                      <img src={Col} alt="Colombia" width={'100%'} height={"auto"} />
                </div>
                <div className="preItemInfo">

                  <div className="preItemTitle">Colombia | Case Study: Telecom</div>
                  <div className="preItemText">
                  Nexointell was retained by a multinational public credit institution to conduct due diligence on a telecommunications company in Colombia. The company was a relevant player in the niche of satellite internet access, focused on small villas and rural areas in the interior of Colombia. Our work aimed to identify potential red flags involving politival connections, ethical and legal standards, as well as the company’s local business track-record. Nexointell completed a combination of discrete inquiries with local sources of the industry, legal and regulatory segments, as well as targeted public record research to help guide the client’s investment decision.
                  </div>
                </div>
            </div>

            <div className={`preItem ${pais === "cos" ? 'preItemActive' : '' }`}>
                <div className="preItemMap">
                      <img src={Cos} alt="Costa Rica" width={'100%'} height={"auto"} />
                </div>
                <div className="preItemInfo">

                  <div className="preItemTitle">Costa Rica | Case Study: Telecom</div>
                  <div className="preItemText">
                  We assisted an international telecommunications company to understand the political agenda and interests surrounding projected privatization efforts of telecommunications companies in Central America. Nexointell conducted a stakeholder mapping profiling the decision makers, underlying interests and their network and influence in the privatization discussions.
                  </div>
                </div>
            </div>


            <div className={`preItem ${pais === "ecu" ? 'preItemActive' : '' }`}>
                <div className="preItemMap">
                      <img src={Ecu} alt="Ecuador" width={'100%'} height={"auto"} />
                </div>
                <div className="preItemInfo">

                  <div className="preItemTitle">Ecuador | Case Study: Finance</div>
                  <div className="preItemText">
                  Nexointell conducted a multijurisdictional asset search on behalf of a UK-based law firm. The client wished to map the assets of the managers of an investment fund accused of running a Ponzi scheme. The individuals were U.S.-based but had relevant footprint in Latin America. We conducted source inquiries in three countries focused on identification of assets and mapping out profile and reputation of the individuals. Additionally, we retrieved property records and local public record information in four countries to build a comprehensive asset profile of the individuals.
                  </div>
                </div>
            </div>

            <div className={`preItem ${pais === "mex" ? 'preItemActive' : '' }`}>
                <div className="preItemMap">
                      <img src={Mex} alt="Mexixo" width={'100%'} height={"auto"} />
                </div>
                <div className="preItemInfo">

                  <div className="preItemTitle">Mexico | Case Study: Mining</div>
                  <div className="preItemText">
                  Nexointell conducted on-the ground intelligence gathering and risk assessment in five municipalities in the interior of Mexico, where our client, an international mining company, was implementing mining operations. The objective of the work was to identify the existing political risks, common and violent crime situation, presence of drug cartels, social conflicts and the existence, activity and capacity to mobilize, of the labor union groups of each municipality. The investigation allowed the client to develop risk mitigation strategies, define political and institutional relations approaches, and determine the criteria of outsourced security services providers of its mining sites and operations in Mexico.
                  </div>
                </div>
            </div>


            <div className={`preItem ${pais === "par" ? 'preItemActive' : '' }`}>
                <div className="preItemMap">
                      <img src={Par} alt="Paraguay" width={'100%'} height={"auto"} />
                </div>
                <div className="preItemInfo">

                  <div className="preItemTitle">Paraguay | Case Study: Contraband</div>
                  <div className="preItemText">
                  Over the course of several months, we conducted an investigation for an international client interested in understanding whether a local multimedia manufacturer based in Paraguay was in fact producing what he was declaring to the client, who received royalties over the production. Our investigation included the identification of the equipment being used, the manufacturer's work regime and workforce, as well as the quantities of product being declared and transported to neighboring countries - mainly Argentina and Brazil. The work allowed the client to identify a severe understatement in production numbers, which accounted for millions of dollars in unpaid royalties.
                  </div>
                </div>
            </div>


            <div className={`preItem ${pais === "per" ? 'preItemActive' : '' }`}>
                <div className="preItemMap">
                      <img src={Per} alt="Peru" width={'100%'} height={"auto"} />
                </div>
                <div className="preItemInfo">

                  <div className="preItemTitle">Peru | Case Study: Mining​</div>
                  <div className="preItemText">
                  A UK-based investment fund retained Nexointell to conduct business intelligence in Peru, focused on the local mining assets of an international mining company. Our focused was to understand the current status of the assets, performance, the local reputation of the parent company as well as any red-flags prior to an investment decision. Nexointell contacted local sources including labor representatives, competitors and local authorities to answer the client’s questions ahead of their investment decision.
                  </div>
                </div>
            </div>

            <div className={`preItem ${pais === "spa" ? 'preItemActive' : '' }`}>
                <div className="preItemMap">
                      <img src={Spa} alt="Spain" width={'100%'} height={"auto"} />
                </div>
                <div className="preItemInfo">

                  <div className="preItemTitle">Spain | Case Study:  Security​</div>
                  <div className="preItemText">
                  How committed is Spain to SCAF (The Future Air Combat System)? Nexointell conducted in-depth research into the European defense business sector to answer this and other questions of where hundreds of millions of dollars would go. The end client, an European manufacturer of defense products, wanted to understand the current relations of some members of the Spanish government with American manufacturers, as well as the perception of the use of diplomatic forces in the negotiations. Through our network of sources we accessed senior officials and industry members familiar with the negotiations, providing the client with a reliable assessment of the scenarios.
                  </div>
                </div>
            </div>

            <div className={`preItem ${pais === "eua" ? 'preItemActive' : '' }`}>
                <div className="preItemMap">
                      <img src={Eua} alt="United States" width={'100%'} height={"auto"} />
                </div>
                <div className="preItemInfo">

                  <div className="preItemTitle">United States | Case Study: Environment​</div>
                  <div className="preItemText">
                  Nexointell was tasked with understanding the reputation of a group of executives of an environmental company based in the U.S. ahead of a planned merger with a Latin American group of the same sector. Our work involved source inquiries aimed at profiling the track-record and reputation of the individuals and companies involved, as well as identifying indications of conflicts of interests, political connections, legal, compliance and regulatory issues.
                  </div>
                </div>
            </div>


            <div className={`preItem ${pais === "ven" ? 'preItemActive' : '' }`}>
                <div className="preItemMap">
                      <img src={Ven} alt="Venezuela" width={'100%'} height={"auto"} />
                </div>
                <div className="preItemInfo">

                  <div className="preItemTitle">Venezuela | Case Study: EPC</div>
                  <div className="preItemText">
                  Nexointell was hired to determine the reputation of a group of construction entrepreneurs in Venezuela that would lead an infrastructure project in Colombia. The client, an international investor in the sector, wanted to understand the risks involved as well as validate the very low profile of these businessmen, their potential political ties with the Venezuela regime and how they were awarded the project. The results of the work indicated political proximity and a lack of proven activity in the industry, which in turn enabled the client to understand potential risks of corruption and FCPA violations and take a better and informed decision about the continuity of its investment.
                  </div>
                </div>
            </div>




        </div>


        
      </div>
    </section>
  )
}

export default Presence