import React, {useState} from 'react'
import './floattop.css'
import Top from '../../image/top.webp'

function FloatTop() {


    const [ pageYPosition, setPageYPosition ] = useState(0);

    function getPageYAfterScroll(){
        setPageYPosition(window.scrollY);
    }

window.addEventListener('scroll', getPageYAfterScroll);


  return (
    <>
    {pageYPosition > 900 &&
        <div className="floatTop">
        <a href="#top"><img src={Top} alt="Go Top" width={"100%"} height={"auto"}/></a>
    </div>
    
    }
    </>
    
  )
}

export default FloatTop