import React from "react";
import { Navigate } from "react-router-dom";
import { UserAuth } from "../services/AuthContext";

export const ProtectedRoutes = ({children})=>{
 const {user} = UserAuth();
 if (!user){
     return <Navigate to='/login' />
 }

 return children
}