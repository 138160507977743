import React, {useState, useEffect} from 'react';
import * as Images from '../../services/photos'
import './imagelist.css'
import SIstemSidebar from '../../components/SistemSideBar';
import ImageItem from '../../components/ImageItem';

function ImageList() {

  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]); 

  useEffect(()=>{
    getImages() ;
  },[]);
  
  const getImages = async()=>{
    setLoading(true);
  
    setImages(await Images.getAllNews());
  
    setLoading(false);
  }

  const handleDeleteClick = async (name) => {
    await Images.deleteNewsPhoto(name);
    getImages();
  }


  return (

    <div className="sisContainer">

      <SIstemSidebar />

      <div className="contentArea">
       
        <div className="contentTitle">Image List</div>
        {loading &&
          <div className="loading">
            Loading....
          </div>
        
        }
        <div className="imageArea2">
            {images.map((item, index)=>(
              <ImageItem key={index} url={item.url} name={item.name}  onDelete={handleDeleteClick} />
            ))}
        </div>
      </div>

    </div>
    
  )
}

export default ImageList