import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {newsCollectionRef} from '../../services/fireref';
import { addDoc} from "firebase/firestore";
import SIstemSidebar from '../../components/SistemSideBar';
import './newsupload.css'

function NewsUpload() {

  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);
  const [title, setTitle]=useState('');
  const [description, setDescription]=useState('');
  const [link, setLink]=useState('');
  const [thumb, setThumb]=useState('');
  const [time, setTime]=useState(()=>new Date());

  const handleAdd = async(e)=>{
    e.preventDefault();
    setUploading(true);
    await addDoc( newsCollectionRef, {
        title,
        description,
        link,
        thumb,
        time
      });
    //navigate('/');
    setTitle('');
    setDescription('');
    setLink('');
    setThumb('');
    setUploading(false);
    navigate('/newslist')
}


  return (
    <div className="sisContainer">
      <SIstemSidebar />
      <div className="contentArea">
       
        <div className="contentTitle">Upload News</div>

        <div className="formArea2">
        <form  method='POST' className="newsForm" onSubmit={handleAdd}>
            <p className="label">Title</p>
            <input type="text" placeholder="Title" value={title} required onChange={(e)=>{setTitle(e.target.value)}} style={{backgroundColor:'#fff'}}/>
            <p className="label">Description</p>
            <textarea placeholder="Description" value={description} required onChange={(e)=>{setDescription(e.target.value)}} style={{backgroundColor:'#fff'}}/>
            <p className="label">News Link</p>
            <input type="text" placeholder="News Link" value={link} required onChange={(e)=>{setLink(e.target.value)}} style={{backgroundColor:'#fff'}}/>
            <p className="label">Thumb URL</p>
            <input type="text" placeholder="Thumb URL" value={thumb} required onChange={(e)=>{setThumb(e.target.value)}} style={{backgroundColor:'#fff'}}/>
            
            <input type="submit" value="SEND" />

            {uploading &&
              <span>uploading...</span>
            }
          </form>
      </div>
      </div>
    </div>
    
  )
}

export default NewsUpload