import React, { useRef } from 'react';
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import './imageitem.css';

function ImageItem({url, name, onDelete}) {

   const textRef = useRef();

  //Function to add text to clipboard
  const copyToClipboard = () => {
    // Text from the html element
    let copyText = textRef.current.value;
    // Adding text value to clipboard using copy function
    let isCopy = copy(copyText);

    //Dispalying notification
    if (isCopy) {
      toast.success("Copied to Clipboard");
    }
  };

  return (
    <div className="container-photo">
        <div className="imagem">
            <img src={url} alt={name}/>
        </div>
        <div className="infos">
            
            <div className="url">
                <span>URL:     </span>
                <input value={url} disabled type="text" ref={textRef} />
                <button onClick={copyToClipboard} className='copy'>COPY URL</button>
                <button onClick={()=>onDelete(name)} className='delete' >DELETE IMAGE</button>
            </div>
        </div>
    </div>
  )
}

export default ImageItem