import {storage} from '../libs/firebase';
import { ref, listAll, getDownloadURL, uploadBytes, deleteObject} from 'firebase/storage';
import {v4 as createID} from 'uuid';



export const getAllNews = async ()=>{
    let list =[];
    const imagesFolder = ref(storage, "/news-thumb");
    const imageList = await listAll(imagesFolder);
    for(let i in imageList.items){
        let imageURl = await getDownloadURL(imageList.items[i]);

        list.push({
            name: imageList.items[i].name,
            url: imageURl
        })
    }
    return list;
};


export const insertNews = async (file)=>{
    if(['image/jpeg', 'image/jpg', 'image/png', 'image/webp'].includes(file.type)) {

        let randomName = createID();
        let newFile = ref(storage, `news-thumb/${randomName}`);

        let upload = await uploadBytes(newFile, file);
        let photoUrl = await getDownloadURL(upload.ref);

        return { name: upload.ref.name, url: photoUrl };
    } else {
        return new Error('This file is not a image');
    }
}

export const deleteNewsPhoto = async (name) => {
    let photoRef = ref(storage, `news-thumb/${name}`);
    await deleteObject(photoRef);
}
